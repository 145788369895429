<template>
  <div class="home-container">
    <div class="home-container1">
      <div class="home-testimonial-card">
        <div class="home-container2">
          <img
            alt="profile"
            src="/playground_assets/profil.jpg"
            class="home-image"
          />
          <div class="home-container3">
            <div class="home-container4">
              <span class="home-text">Frederick S. Pokoj</span>
              <span class="home-text1">Senior IT Consultant</span>
            </div>
            <svg viewBox="0 0 1024 1024" class="home-icon">
              <path
                d="M225 448c123.712 0 224 100.29 224 224 0 123.712-100.288 224-224 224s-224-100.288-224-224l-1-32c0-247.424 200.576-448 448-448v128c-85.474 0-165.834 33.286-226.274 93.726-11.634 11.636-22.252 24.016-31.83 37.020 11.438-1.8 23.16-2.746 35.104-2.746zM801 448c123.71 0 224 100.29 224 224 0 123.712-100.29 224-224 224s-224-100.288-224-224l-1-32c0-247.424 200.576-448 448-448v128c-85.474 0-165.834 33.286-226.274 93.726-11.636 11.636-22.254 24.016-31.832 37.020 11.44-1.8 23.16-2.746 35.106-2.746z"
              ></path>
            </svg>
            <span class="home-text2">
              <span><b>Welcome</b> to my personal internet landing page.</span>
              <br />
              <div class="home-text3"><br></div>
              <span>Feel free to <b>have a look</b> around and <b>leave me a message</b>.</span>
              <br />
            </span>
          </div>
        </div>
      </div>
      <div class="home-social-bar">
        <div class="home-container5">
          <a href="https://www.instagram.com/frederick_pokotschi">
            <img
              alt="Instagram"
              src="/playground_assets/insta.svg"
              class="home-image1"
            />
          </a>
          <a href="https://open.spotify.com/user/g73umvcuh73xxmcds15tmevrb">
            <img
              alt="Spotify"
              src="/playground_assets/spotify.svg"
              class="home-image2"
            />
          </a>
          <a href="https://www.linkedin.com/in/frederickpokoj/">
            <img
              alt="LinkedIn"
              src="/playground_assets/linked.svg"
              class="home-image3"
            />
          </a>
          <a href="https://www.xing.com/profile/Frederick_Pokoj">
            <img
              alt="Xing"
              src="/playground_assets/xing.svg"
              class="home-image4"
            />
          </a>
          <a href="mailto:mail@frederick-pokoj.de">
            <img
              alt="Mail"
              src="/playground_assets/mail.svg"
              class="home-image5"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  metaInfo: {
    title: 'Welcome',
    meta: [
      {
        property: 'og:title',
        content: 'Welcome',
      },
    ],
  },
}
</script>

<style scoped>
.home-container {
  gap: 50;
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: row;
  background-size: cover;
  justify-content: center;
  background-image: url("/playground_assets/pexels-c%C3%A1tia-matos-1072179-1500w.jpg");
  background-repeat: no-repeat;
  background-position: center;
}
.home-container1 {
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.home-testimonial-card {
  flex: 1;
  width: auto;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxwidth);
  align-self: stretch;
  background: #fff;
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  align-items: flex-end;
  margin-right: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: rgba(217, 217, 217, 0.8);
}
.home-container2 {
  flex: 0 0 auto;
  width: auto;
  height: XLarge;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.home-image {
  width: var(--dl-size-size-xlarge);
  height: var(--dl-size-size-xlarge);
  align-self: flex-start;
  object-fit: cover;
  flex-shrink: 0;
  margin-right: var(--dl-space-space-threeunits);
  border-radius: var(--dl-radius-radius-round);
  object-fit: cover;
  object-position: 0% 20%;
}
.home-container3 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.home-container4 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-end;
  margin-left: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
}
.home-text {
  font-size: 3rem;
  font-weight: 600;
}
.home-text1 {
  font-size: 1.5rem;
  font-weight: 600;
}
.home-icon {
  width: var(--dl-size-size-small);
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  flex-shrink: 0;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text2 {
  color: var(--dl-color-gray-black);
  font-size: 1.2rem;
  max-width: 500px;
}
.home-text3 {
  color: var(--dl-color-gray-black);
  font-size: 0.4rem;
  max-width: 500px;
}
.home-testimonial {
  width: auto;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  margin-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  justify-content: space-between;
}
.home-social-bar {
  width: 114px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  z-index: 100;
  position: relative;
  align-self: stretch;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: row;
  justify-content: center;
  background-color: rgba(217, 217, 217, 0.8);
}
.home-container5 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-image1 {
  width: var(--dl-size-size-small);
  margin-top: var(--dl-space-space-halfunit);
  object-fit: cover;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-image2 {
  width: var(--dl-size-size-small);
  margin-top: var(--dl-space-space-halfunit);
  object-fit: cover;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-image3 {
  width: var(--dl-size-size-small);
  margin-top: var(--dl-space-space-halfunit);
  object-fit: cover;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-image4 {
  width: var(--dl-size-size-small);
  margin-top: var(--dl-space-space-halfunit);
  object-fit: cover;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-image5 {
  width: var(--dl-size-size-small);
  margin-top: var(--dl-space-space-halfunit);
  object-fit: cover;
  margin-bottom: var(--dl-space-space-halfunit);
}
@media(max-width: 991px) {
  .home-container1 {
    flex-direction: column;
  }
  .home-testimonial-card {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-social-bar {
    width: 100%;
    align-self: stretch;
    flex-direction: row;
  }
  .home-container5 {
    flex-direction: row;
  }
  .home-image1 {
    margin-top: 0px;
    margin-right: var(--dl-space-space-unit);
    margin-left: var(--dl-space-space-unit);
    margin-bottom: 0px;
  }
  .home-image2 {
    margin-top: 0px;
    margin-right: var(--dl-space-space-unit);
    margin-left: var(--dl-space-space-unit);
    margin-bottom: 0px;
  }
  .home-image3 {
    margin-top: 0px;
    margin-right: var(--dl-space-space-unit);
    margin-left: var(--dl-space-space-unit);
    margin-bottom: 0px;
  }
  .home-image4 {
    margin-top: 0px;
    margin-right: var(--dl-space-space-unit);
    margin-left: var(--dl-space-space-unit);
    margin-bottom: 0px;
  }
  .home-image5 {
    margin-top: 0px;
    margin-right: var(--dl-space-space-unit);
    margin-left: var(--dl-space-space-unit);
    margin-bottom: 0px;
  }
}
@media(max-width: 767px) {
  .home-container1 {
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .home-container4 {
    margin-left: 0;
  }

  .home-testimonial-card {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-container2 {
    flex-direction: column;
  }
  .home-image {
    align-self: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-icon {
    height: var(--dl-size-size-small);
  }
  .home-testimonial {
    margin-right: var(--dl-space-space-twounits);
  }
  .home-social-bar {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}

@media(max-width: 479px) {
  .home-container1 {
    margin-top: calc(0px - var(--dl-space-space-fiveunits));
  }
  .home-testimonial-card {
    padding: var(--dl-space-space-unit);
    align-self: stretch;
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .home-text {
    align-self: flex-start;
    font-size: 2.5rem;
    font-weight: 600;
  }
  .home-text2 {
    text-align: left;
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
  }
  .home-testimonial {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-social-bar {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-image1 {
    margin-right: var(--dl-space-space-halfunit);
    margin-left: var(--dl-space-space-halfunit);
  }
  .home-image2 {
    margin-right: var(--dl-space-space-halfunit);
    margin-left: var(--dl-space-space-halfunit);
  }
  .home-image3 {
    margin-right: var(--dl-space-space-halfunit);
    margin-left: var(--dl-space-space-halfunit);
  }
  .home-image4 {
    margin-right: var(--dl-space-space-halfunit);
    margin-left: var(--dl-space-space-halfunit);
  }
  .home-image5 {
    margin-right: var(--dl-space-space-halfunit);
    margin-left: var(--dl-space-space-halfunit);
  }
}
</style>
